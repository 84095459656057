const DIRECTION = {
  RIGHT: "right",
  LEFT: "left",
  UP: "up",
  DOWN: "down",
};

const PAGES = [{ name: "CODE" }, { name: "MOTION" }, { name: "DESIGN" }];

const BODY_TEXT = [
  {
    type: "code",
    text:
      "I’m a full stack developer who feels most at home in the front end. I work with clients and teams to create engaging digital products and experiences. I care about clean code, great UX, and the person who ends up using what I build. I’ve been working on in-house projects big and small for the last three years in everything from IoT to AI in healthcare.",
  },
  {
    type: "motion",
    text:
      "Motion has always been an essential part of my work as a developer and designer. This year is the first time it’s taking a front seat. Whether it’s embedded in a website I build, an AR experience, or an explainer video - I sweat the small stuff to make sure everything goes swimmingly.",
  },
  {
    type: "design",
    text:
      "Design is at the heart of everything I do. I'm really passionate about building connections between design and development teams, building solid design systems, and creating usable, accessible, engaging interfaces.",
  },
];

const SECTIONS = [
  { type: "code", description: "I 🖤 building digital experiences." },
  { type: "motion", description: "I 🖤 creating engaging interactions." },
  {
    type: "design",
    description: "I 🖤 designing elegant interfaces.",
  },
];

export { DIRECTION, PAGES, BODY_TEXT, SECTIONS };
