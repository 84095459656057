<script>
  import { SECTIONS } from "../utils/constants";
  import { afterUpdate } from "svelte";
  import { quintOut } from "svelte/easing";
  import { fade, crossfade } from "svelte/transition";
  export let section = SECTIONS[0];
  let animate = "";
  let placeholder;

  afterUpdate(() => {
    if (section !== placeholder) {
      console.log("update", placeholder, animate);
      animate = "animate-in";
      placeholder = section;
    } else {
      animate = "";
    }
  });
</script>

<style>
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem;
  }
  .container {
    width: 100%;
  }
  .footer-top {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
  .animate {
    animation: fade-in cubic-bezier(0.47, 0, 0.745, 0.715) 0.75s;
  }
  .footer-bottom {
    font-size: 1rem;
    text-transform: capitalize;
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @keyframes fade-in {
    0% {
      transform: translate(
        matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -14.9466, 0, 1)
      );
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      transform: translate(
        matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -14.9466, 0, 0)
      );
      opacity: 1;
    }
  }
</style>

<div class="footer">
  <div class="container">
    <div class="flex-container">
      <div>
        <h4
          transition:fade
          key={section.type}
          class="footer-top animate-{section.type}">
          {section.type}
        </h4>
        <p key={section.description} class="footer-bottom">
          {section.description}
        </p>
      </div>
    </div>
  </div>
</div>
