<script>
  import { fade } from "svelte/transition";

  const underConstruction = "Hello! Working on the site, will be back soon!";
</script>

<style>
  .cover {
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: multiply;
    background: #100a54;
    color: white;
  }
  .card {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem;
  }
  h2 {
    font-size: 0.875rem;
    font-weight: 500;
    max-width: 60%;
  }
</style>

<div transition:fade class="cover">
  <div class="card">
    <h2>{underConstruction}</h2>
    <a href="mailto:hello@codemotiondesign.com">Get in touch. 💌</a>
  </div>
</div>
