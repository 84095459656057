import App from "./App.svelte";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
  apiKey: GOOGLE_API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: FIREBASE_DB_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

firebase.auth();
new firebase.auth.GoogleAuthProvider();

firebase.firestore();

const storage = firebase.storage();
export const reference = storage.ref();

const app = new App({
  target: document.body
});

export default app;
