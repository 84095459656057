<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { reference } from "../main.js";
  import { SECTIONS } from "../utils/constants.js";

  let cardSrc = type => fetchSrc(type);

  let sections;
  let elemInView = SECTIONS[0];
  export let setSection;

  onMount(() => {
    sections = document.querySelectorAll("section.video");

    let observer = new IntersectionObserver(
      (entries, self) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.5) {
            intersectionHandler(entry);
          }
        });
      },
      { threshold: 0.5 }
    );

    function intersectionHandler({ target }) {
      elemInView = SECTIONS.find(section => section.type === target.id);
      setSection(elemInView);
    }

    sections.forEach(section => {
      observer.observe(section);
    });
  });

  async function fetchSrc(type) {
    const bucket = reference.child(`animations/${type}.mp4`);

    return await bucket.getDownloadURL().then(url => {
      return url;
    });
  }
</script>

<style>
  section {
    position: relative;
    height: 100vh;
    width: 100vw;
    scroll-snap-align: center;
  }

  .video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 40em;
  }

  video {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
</style>

<main>
  {#each SECTIONS as section}
    <section class="video" id={section.type}>
      {#await cardSrc(section.type)}
        <div transition:fade class="video-wrapper" />
      {:then src}
        <div transition:fade class="video-wrapper">
          <video
            autoplay
            loop
            muted
            preload="metadata"
            poster={`./assets/${section.type}.png`}>
            <source {src} data-wf-ignore="true" />
          </video>
        </div>
      {/await}
    </section>
  {/each}
</main>
