<script>
  import { DIRECTION, BODY_TEXT, SECTIONS } from "./utils/constants.js";
  import Navbar from "./components/Navbar.svelte";
  import Footer from "./components/Footer.svelte";
  import Layout from "./components/Layout.svelte";
  import Sidebar from "./components/Sidebar.svelte";
  import EscButton from "../public/assets/EscButton.svelte";
  import Chat from "../public/assets/Chat.svelte";
  import HelloButton from "../public/assets/HelloButton.svelte";
  import ContactForm from "./components/ContactForm.svelte";
  import Construction from "./components/Construction.svelte";
  import Overlay from "./components/Overlay.svelte";

  let showOverlay = false;
  let showContact = false;
  let devModeOn = true;
  let curSection = SECTIONS[0];

  const setSection = newSection => {
    curSection = newSection;
  };

  devModeOn = false || localStorage.getItem("dev_mode");

  let currentCard = null;

  const toggleOverlay = card => {
    currentCard = card;
    showOverlay = !showOverlay;
  };
  const toggleContact = () => {
    const prevContact = !showContact;
    showContact = prevContact;
  };
  function getBody(header) {
    const arr = BODY_TEXT.filter(obj => obj.type === header);
    const { text } = arr[0];
    return text;
  }
  const showContactButton = !showContact || !showOverlay;
</script>

<Navbar />
{#if !devModeOn}
  <Construction />
{:else}
  <Footer section={curSection} />
{/if}
<Layout {setSection} />
